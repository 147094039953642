import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const CustomizedWigforWomen = () => {

  const seoData = {
    title: 'Hair Replacement For Women in Delhi - Radiance',
    description: 'Depression due to baldness? Replace your hair with 100% original human hair extensions through a complete non-surgical hair replacement for women in Delhi.',
    keywords: ['Hair Replacement For Women in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/hair-replacement-for-women.png",
      "https://www.radiancehairstudio.com/hair-replacement-for-women1.png",
      "https://www.radiancehairstudio.com/hair-replacement-for-women2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/hair-replacement-for-women.php",
    "category": "https://www.radiancehairstudio.com/hair-replacement-for-women.php",
    "description": "Depression due to baldness? Replace your hair with 100% original human hair extensions through a complete non-surgical hair replacement for women in Delhi.",
    "disambiguatingDescription": "Hair Replacement For Women in Delhi",
    "serviceType": "Hair Replacement For Women"
  };  

  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
        img: "/hair-replacement-for-women.png",
        content: (
            <div>
                <h2>Best Customized Wig for Women in Delhi</h2>
                <p>
                    If you are experiencing excessive hair loss or anything associated with it, <a href="/wigs-for-womens.php" style={{ color: "#373737"}}>wigs</a> might be worn. Wigs that are custom-made can be produced to your specifications and, more importantly, to fit you precisely.
                </p>
                <h2>Complete Natural Hair, Just Like Yours!</h2>
                <p>
                You're still wondering how you might find the ideal wig! No matter what, they've got your back! Simply let them know how you would like your hair to appear; they undoubtedly have the best selection of wigs, even if they don't have the precise one.No worries!If you would like, we can create your wig, also known as your natural hair. They will create a brown wig that will appear exactly like your natural hair if you have brown hair and you don't want to change the shade since you think it suits you. I promise you that you will adore it. 
                </p>
                <p>
                Although we already have a huge assortment of wigs at RADIANCE HAIR STUDIO, they always put their customers first therefore they have come with this exclusive custom made wig thing!. Apart from this, there's another reason to choose RADIANCE as their wigs are made of 100 percent natural human hair which is actually a great thing. 

                </p>
            </div>
        ),
    },
];

const sectionS3 = [
    {
        smallImg: "/hair-replacement-for-women1.png",
        largeImg: "/hair-replacement-for-women2.png",
        content: (
            <div>
                <h2>Benefits: Stay Trendy</h2>
                <p>
                As most of us are already aware, wigs can save lives. However, there is unquestionably a thin line separating custom-made wigs from regular wigs.
                </p>
                <ul>
                    <li>
                      <b>Color</b>: Finding a wig that perfectly matches your <a href="/testimonials.php">natural</a> hair shade can be challenging. At RADIANCE HAIR STUDIO, we offer a wide range of shades to match your hair color effortlessly, ensuring a seamless and natural look.
                    </li>
                    <li>
                      <b>Style</b>: Whether your hair is curly, wavy, or straight, a custom-made wig is designed specifically to match your natural texture. Since it’s tailored just for you, it blends perfectly with your existing hair for a flawless appearance.
                    </li>
                    <li>
                      <b>Length</b>: Choosing between long and short hair can be a struggle. With our 100% natural human hair wigs, you don’t have to worry—whether you prefer short or long hair, you’ll always look stunning!
                    </li>
                    <li>
                      <b>Perfect Fit</b>: Standard wigs don’t always sit comfortably or stay secure. A custom-made wig, however, is designed to fit you precisely, ensuring comfort and a natural feel just like your own hair.  
                    </li>
                </ul>
                <p>At <a href="/" style={{ color: "#373737"}}> <strong>RADIANCE HAIR STUDIO</strong></a>, we believe in providing high-quality, custom solutions for non surgical hair replacement in delhi that enhance your confidence and beauty. Get yours today and experience the difference!</p>

                <p>The concerns that were preventing you from wearing a wig made of real human hair have all been resolved. Get yourself one and fight that DEVIL with all your WILL! </p>
            </div>
        ),

    },
];

const sectionS4 = [
    {
        title: "Frequently Asked Questions About Custom-Made Wigs",
        description: "Many people have concerns when it comes to wigs, especially custom-made ones. To help you make an informed decision, we’ve answered some of the most common questions below.",
        description2: "If you have more questions or need expert guidance, visit RADIANCE HAIR STUDIO for a personalized consultation and find the perfect wig for you!",
        faq: [
            {
                title: "Should I buy more than one wig?",
                description: "If you’re opting for a custom-made wig, you may not need multiple wigs. A custom wig is designed to match your natural hair perfectly, giving you a seamless and natural look. At RADIANCE HAIR STUDIO, our certified hair experts will guide you in choosing the best option tailored to your needs.",
            },
            {
                title: "Which type of wig looks the most natural?",
                description: "To achieve a realistic appearance, always opt for a wig made from 100% natural human hair rather than synthetic fibers. At RADIANCE HAIR STUDIO, we specialize in high-quality human hair wigs that ensure a natural and flawless finish, making them nearly indistinguishable from real hair.",
            },
            {
                title: "Do I need a full wig if I have thinning hair or partial hair loss?",
                description: "If you are experiencing partial hair loss or thinning, you do have an alternative to a full wig—a hair topper. However, toppers don’t always provide the best coverage. A custom-made wig with 100% natural human hair can be designed specifically to blend with your existing hair for a more seamless and natural look.",
            },
            {
                title: "What is the difference between a ready-made wig and a custom-made wig?",
                description: "While ready-made wigs can be convenient, nothing compares to the personalized touch of a custom wig. A custom-made wig is crafted to match your natural hair color, texture, and style, ensuring that it looks uniquely yours. It offers a perfect fit and enhances your overall appearance with a natural finish.",
            },
        ],
    },
];


  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Replacement For Women" banner="/hair-replacement-for-women111.png" />
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Best Customized Wig for Women in Delhi" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default CustomizedWigforWomen;
